import Joi from 'joi';

export const validationOptions = {
  errors: {
    wrap: {
      label: '',
    },
  },
  abortEarly: false
};

const passwordRegValue = '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\\[\\]\\"\\\'\\`(){}?\\\\|,.<>;:!~*_@#$%^&+=-])(?=.{14,})'; //NOSONAR
const nameRegValue = '^([^<]|a z|A Z|1 9|(.\\.))*$'
const textFieldWithLengthRegValue = '^([^<]|a z|A Z|1 9|(.\\.))*$'

let validationFields = {
  email: Joi.string().email({ tlds: { allow: false } }).required().messages({
    'string.empty': 'This field may not be blank.',
    'string.email': 'Email must be a valid email',
  }),
  password: Joi.string()
    .pattern(new RegExp(passwordRegValue))
    .messages({
      'string.empty': 'This field may not be blank.',
      'string.min': 'Must have at least 8 characters',
      'object.regex': 'Must have at least 8 characters',
      'string.pattern.base': 'Must be 14 characters in length, at least 1 upper and 1 lower case letter, at least one number, at least one special character'
    }),
  confirmPassword: Joi.any().equal(Joi.ref('password')).messages({
    'any.only': 'Passwords must match'
  }),
  privacyPolicy: Joi.boolean().valid(true).required().messages({
    'string.empty': 'Please confirm that you have read the privacy policy.',
    'any.only': 'Please confirm that you have read the privacy policy.'
  }),
  termsOfUse: Joi.boolean().valid(true).required().messages({
    'string.empty': 'Please confirm that you have read the terms of use.',
    'any.only': 'Please confirm that you have read the terms of use.'
  }),
  textField: Joi.string().required().messages({
    'string.empty': 'This field may not be blank.'
  }),
  name: Joi.string()
    .pattern(new RegExp(nameRegValue))
    .max(30).required().messages({
      'string.empty': 'This field may not be blank.',
      'string.max': 'Must be 30 characters or less in length',
      'string.pattern.base': 'Text should not contain any HTML Tags'
    }),
  textFieldWithLength: Joi.string()
    .pattern(new RegExp(textFieldWithLengthRegValue))
    .max(100).required().messages({
      'string.empty': 'This field may not be blank.',
      'string.max': 'Must be 100 characters in length',
      'string.pattern.base': 'Text should not contain any HTML Tags'
    }),
  dropDown: Joi.string().required().messages({
    'string.empty': 'This field may not be blank.'
  }),
};
export const validateFields = Joi.object(validationFields).fork(Object.keys(validationFields), schema => schema.optional());
