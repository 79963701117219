import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import styled from '@emotion/styled';
import { useTheme, FormLabel, FormControl, FormHelperText, Autocomplete, TextField, Box } from '@mui/material';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import HDToolTip from '../HDToolTip';
import {ResponsiveThemeValue} from '../../commons/commonFunctions';

export interface HDDropDownTypes {
  fieldLabel?: string;
  firstFieldLabel?: string;
  dropDownItem?: Array<{ keyValue: string | number; keyName: string, isDisabled?: boolean }>;
  value?: string | number | object;
  onChange?: (arg0: any) => void; // eslint-disable-line
  onClose?: (arg0: any) => void; // eslint-disable-line
  onOpen?: (arg0: any) => void; // eslint-disable-line
  variant?: 'filled' | 'outlined' | 'standard';
  sx?: Array<object> | object;
  fullWidth?: boolean;
  disabled?: boolean;
  error?: boolean;
  defaultValue?: string;
  errorMsg?: any;
  showToolTip?: boolean;
  toolTipText?: any;
  isAutoComplete?: boolean;
  autoCompleteOptions?: any;
  dataTestId?: any;
  isCheckmark?: boolean;
  id?: string;
  placeHolder?:string;
  labelColor?: string;
  toolTipPlacement?:string;
}

const HDDropDown = (props: HDDropDownTypes) => {
  const theme = useTheme();
  const isDarkTheme = useTheme().palette.mode === 'dark';

  const StyledMenuItem = styled(MenuItem)(() => ({
    background: ResponsiveThemeValue(theme,'#FFFFFF' , '#0B0C10'),
    padding: '0 20px 0 20px',
    borderBottom: props.isCheckmark ? 'none' : ResponsiveThemeValue(theme, '1px solid #CCC' , '1px solid #3C3D40'),
    fontFamily: 'Avenir',
    fontWeight: 500,
    fontStyle: 'normal',
    fontSize: '16px',
    lineHeight: 3,
    color: ResponsiveThemeValue(theme,'#002136' , '#FFF'),
    '&:hover': {
      color: '#fff',
      background: '#18768C',
    },
    '&:focus': {
      color: '#fff',
      background: '#18768C',
    },
    '&.Mui-selected': {
      backgroundColor: props.isCheckmark ? 'transparent' : 'rgba(25, 118, 210, 0.08)',
      color: 'inherit',
    },
    '.list-item': {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      borderBottom: props.isCheckmark ? ResponsiveThemeValue(theme, '1px solid #CCC' , '1px solid #3C3D40') : 'none',
    }
  }));

  const StyledDisabledMenuItem = styled(MenuItem)(() => ({
    background: 'rgba(100, 106, 124, 0.2)',
    padding: '0 20px 0 20px',
    borderBottom: props.isCheckmark ? 'none' : ResponsiveThemeValue(theme, '1px solid #CCC' , '1px solid #3C3D40'),
    fontFamily: 'Avenir',
    fontWeight: 500,
    fontStyle: 'normal',
    fontSize: '16px',
    lineHeight: 3,
    color: '#A9A9A9',

    '&:hover': {
      color: '#A9A9A9',
      background: 'rgba(100, 106, 124, 0.2)',
    },
    '&.Mui-selected': {
      backgroundColor: props.isCheckmark ? 'transparent' : 'rgba(25, 118, 210, 0.08)',
      color: 'inherit',
    },
    '.list-item': {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      borderBottom: props.isCheckmark ? ResponsiveThemeValue(theme, '1px solid #CCC' , '1px solid #3C3D40') : 'none',
    }
  }));

  const MenuProps = {
    PaperProps: {
      style: {
        borderRadius: '10px',
        border: ResponsiveThemeValue(theme, '1px solid #646A7C' , '1px solid #A9A9A9'),
      },
    },
  };

  const FormHelperTextStyled = styled(FormHelperText)(() => ({
    fontFamily: 'Avenir',
    fontWeight: 300,
    fontSize: '16px',
    lineHeight: '24px',
    color: ResponsiveThemeValue(theme,'#646A7C' , '#A9A9A9'),
    marginLeft: 0,
    marginTop: '8px',
  }));

  const formLabelStyle = {
    display: 'flex',
    color:props?.labelColor ? props?.labelColor : 'inherit',
  };

  const toolTipStyle = {
    width: '24px',
    marginLeft: '10px',
    padding: '5px 8px',
  };

  const autoCompleteWrapper = {
    '& .MuiAutocomplete-popper .MuiPaper-root': {
      border: '1px solid ' + (ResponsiveThemeValue(theme,'#646A7C' , '#A9A9A9')),
      color: ResponsiveThemeValue(theme,'#646A7C' , '#A9A9A9'),
      fontFamily: 'Avenir',
      fontWeight: 300,
      fontSize: '16px',
      borderRadius: '10px',
      '&.MuiAutocomplete-listbox': {
        padding: 0,
      },
    },
  };

  const autoCompleteStyle = {
    '& + .MuiAutocomplete-popper .MuiAutocomplete-option': {
      background: ResponsiveThemeValue(theme,'#FFFFFF' ,'#0B0C10'),
      padding: '0 20px 0 20px',
      borderBottom: ResponsiveThemeValue(theme, '1px solid #CCC' , '1px solid #3C3D40'),
      fontFamily: 'Avenir',
      fontWeight: 500,
      fontStyle: 'normal',
      fontSize: '16px',
      lineHeight: 3,
      color: ResponsiveThemeValue(theme,'#002136' , '#FFF'),
      '&:hover': {
        color: '#fff',
        background: '#18768C',
      },
      '&:focus': {
        color: '#fff',
        background: '#18768C',
      },
    },
    '& + .MuiAutocomplete-popper .MuiAutocomplete-option:last-child': {
      borderBottom: 'none',
    },
  };

  const sxSelect = {
    '& .MuiSelect-select img': {
      display: 'none',
    },
  };

  const ErrorIcon = styled('img')(() => ({
    marginRight: '8px'
  }));

  return (
    <>
      <FormControl fullWidth={true}>
        {props.fieldLabel && (
          <FormLabel error={props.error} sx={formLabelStyle}>
            {props.fieldLabel}
            {props.showToolTip && <HDToolTip tooltipText={props.toolTipText} sx={toolTipStyle} toolTipPlacement={props?.toolTipPlacement} />}
          </FormLabel>
        )}
        {props.isAutoComplete ? (
          <Box sx={autoCompleteWrapper}>
            <Autocomplete
              data-testId={props.dataTestId}
              disablePortal
              disabled={props.disabled}
              value={props.value}
              onChange={(event: any, newValue: string | null) => {
                props.onChange(newValue);
              }}
              options={props.autoCompleteOptions}
              renderInput={(params) => <TextField {...params} error={props.error} placeholder={props.placeHolder} />}
              sx={autoCompleteStyle}
              popupIcon={<KeyboardArrowDownOutlinedIcon />}
            />
          </Box>
        ) : (
          <Select
            data-testId={props.dataTestId}
            displayEmpty={true}
            defaultValue={props.defaultValue}
            MenuProps={MenuProps}
            IconComponent={KeyboardArrowDownOutlinedIcon}
            value={props.value}
            onChange={props.onChange}
            onClose={props.onClose}
            onOpen={props.onOpen}
            sx={props.isCheckmark ? sxSelect : props.sx}
            fullWidth={props.fullWidth}
            disabled={props.disabled}
            error={props.error}
            id={props.id}
          >
            <StyledMenuItem value="">{props.firstFieldLabel || 'Select'}</StyledMenuItem>

            {props.dropDownItem &&
              props.dropDownItem.length > 0 &&
              props.dropDownItem.map((el) => {
                if (el.isDisabled) {
                  return (
                    <StyledDisabledMenuItem key={el.keyValue} value={el.keyValue}>
                      <div className="list-item">
                        {el.keyName} (busy)
                      </div>
                    </StyledDisabledMenuItem>
                  );
                }
                return (
                  <StyledMenuItem key={el.keyValue} value={el.keyValue}>
                    <div className="list-item">
                      {el.keyName}
                      {props.isCheckmark && props.value === el.keyValue ? (
                        <img alt='Checked mark logo' src={isDarkTheme ? '../res/hd-ui-kit-images/checkmarkDark.svg' : '../res/hd-ui-kit-images/checkmarkLight.svg'} />
                      ) : ''}
                    </div>
                  </StyledMenuItem>
                );
              })}
          </Select>
        )}
        {props.error && <FormHelperTextStyled error={true}>
          <ErrorIcon src={(window.location.origin) + '/res/hd-ui-kit-images/' + ((ResponsiveThemeValue(theme,  'warning-icon.svg' , 'warning-icon-dark.svg')))} />
          {props.errorMsg}
        </FormHelperTextStyled>}
      </FormControl>
    </>
  );
};

export default HDDropDown;
