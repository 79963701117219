import * as React from 'react';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import { Box, Paper, PaperProps, Popper, PopperProps, styled, FormHelperText, FormLabel } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

interface SelectOptions {
  text: string;
  value: string;
}

interface IProps {
  tooltip?: string;
  label?: string;
  dropDownItem: Array<SelectOptions>;
  clearfunction?: () => void;
  /* Here , I was gettign some issues for other event types , so kept any as we are passing
  library event to our component */
  onChange: (e: any, value: Array<SelectOptions>, reason: unknown) => void;
  column?: any; // can't just pass MRT_Columns because it also has generic def for data of table
  width?: string;
  value?: any;
  isError?: boolean;
  errorMsg?: string;
}

const StyledCheckboxIcon = styled('img')(() => ({
  width: '24px',
  height: '24px',
}));

export interface Multitype {
  title: string;
  year: number;
}

const StyledAutocomplete = styled(Autocomplete)(() => ({
  marginTop: '16px',
  backgroundColor: '#fff',
  borderRadius: '8px',
  '& .MuiAutocomplete-listbox': {
    padding: '0 !important',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderRadius: '8px',
  },
  '& .MuiInputBase-input': {
    height: '30px !important',
  },
  '& .MuiButtonBase-root.MuiChip-root.MuiChip-filled': {
    background: '#646A7C',
    color: '#fff',
    borderRadius: '8px',
    fontSize: '14px',
    fontWeight: '400',
    fontFamily: 'Aventa',
  },
  '& .MuiButtonBase-root.MuiChip-root.MuiChip-filled .MuiChip-deleteIcon': {
    color: '#fff',
  },
  '& .MuiAutocomplete-clearIndicator': {
    border: '1px solid #002136',
    color: '#002136',
  },
}));

const StyledAutocompleteLi = styled('li')(() => ({
  width: '100%',
  '&:hover': {
    color: '#fff !important',
    background: '#18768C !important',
  },
  '&.MuiAutocomplete-option[aria-selected=true]': {
    backgroundColor: '#FFFFFF',
  },
  '&.MuiAutocomplete-option': {
    padding: '12px 16px 13px 16px',
    borderBottom: '1px solid #CCC',
  },
}));

const StyledAutocompleteLibox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  '&:hover': {
    color: '#fff',
    background: '#18768C',
  },
  '&:focus': {
    color: '#fff',
    background: '#18768C',
  },
  '& .MuiCheckbox-root.Mui-checked': {
    color: '#646A7C',
    backgroundColor: 'transparent',
  },
  backgroundColor: 'transparent',
  width: '100%',
}));

const StyledPopperComponent = styled(Popper)(() => ({
  backgroundColor: 'transparent',
  borderRadius: '8px',
  '& .MuiPaper-root': {
    borderRadius: '8px !important',
  },
  '&.MuiAutocomplete-listbox': {
    padding: '0 !important',
  },
}));

const StyledTextField = styled(TextField)(() => ({
  '& .MuiAutocomplete-input': {
    display: 'flex',
    padding: '0 !important',
    marginBottom: '0px',
    alignContent: 'center',
    fontSize: '16px',
    fontWeight: '300',
    fontFamily: 'Avenir',
    color: '#646A7C',
  },
}));

const StyledPaperComponent = styled(Paper)(() => ({
  '& .MuiAutocomplete-listbox': {
    padding: 0,
    borderRadius: '8px',
  },
}));

const StyledOption = styled(Box)(() => ({
  padding: 0,
  margin: 0,
  fontSize: '16px',
  fontWeight: '500',
}));

const CustomPaper = ({ children, ...paperProps }: PaperProps) => <StyledPaperComponent {...paperProps}>{children}</StyledPaperComponent>;

const CustomPopper = ({ children, ...popperProps }: PopperProps) => <StyledPopperComponent {...popperProps}>{children}</StyledPopperComponent>;

export default function HDMultiSelectDropdown({ dropDownItem, label, onChange, column, width, value, selectValue, isError, errorMsg, ...extraProps }: any) {
  // --
  // This functionality allow to remove multi-selected items by clicking on reset button on header
  // It could be improved during refactoring
  const [values, setValue] = React.useState<SelectOptions[]>([]);

  const filteredValue = column?.getFilterValue();

  const FormHelperTextStyled = styled(FormHelperText)(() => ({
    fontFamily: 'Avenir',
    fontWeight: 300,
    fontSize: '16px',
    lineHeight: '24px',
    color: isError ? '#C02195 !important' : '#646A7C',
    marginLeft: 0,
    marginTop: '8px',
  }));

  const ErrorIcon = styled('img')(() => ({
    marginRight: '8px',
  }));

  const formLabelStyle = {
    display: 'flex',
    fontFamily: 'Avenir',
    fontWeight: 300,
    fontSize: '16px',
    lineHeight: '24px',
    color: isError ? '#C02195 !important' : '#646A7C !important',
  };

  React.useEffect(() => {
    let selectedValues: any = [];
    if (selectValue && selectValue.length > 0) {
      selectValue.map((val: any) => {
        if (val === 'Not Applicable' || val === 'Decline to answer') {
          let optionIndex = dropDownItem.findIndex((obj: any, ind: any) => {
            if (obj.value === val) {
              return ind.toString();
            }
          });
          if (parseInt(optionIndex) >= 0) selectedValues = [dropDownItem[optionIndex]];
          setValue([]);
          return;
        } else {
          let optionIndex = dropDownItem.findIndex((obj: any, ind: any) => {
            if (obj.value === val) {
              return ind.toString();
            }
          });
          if (parseInt(optionIndex) >= 0) selectedValues = [...selectedValues, dropDownItem[optionIndex]];
        }
      });
      setValue(selectedValues);
    }
  }, [filteredValue, selectValue]);

  const disableOptions = (option: any) => {
    let flagVal = false;
    if (values.length > 0 && (values?.[0]?.value === 'Not Applicable' || values?.[0]?.value === 'Decline to answer')) {
      if ((values?.[0]?.value === 'Not Applicable' && option.value === 'Not Applicable') || (values?.[0]?.value === 'Decline to answer' && option.value === 'Decline to answer')) {
        flagVal = false;
      } else {
        flagVal = true;
      }
    }
    return flagVal;
  };

  return (
    <>
      {label ? (
        <FormLabel error={isError} sx={formLabelStyle}>
          {label}
        </FormLabel>
      ) : (
        ''
      )}
      <StyledAutocomplete
        ListboxProps={{
          style: {
            maxHeight: '294px',
          },
        }}
        limitTags={2}
        PaperComponent={CustomPaper}
        PopperComponent={CustomPopper}
        popupIcon={<KeyboardArrowDownIcon />}
        sx={{
          marginTop: '8px',
        }}
        multiple
        value={values}
        id="checkboxes-tags-demo"
        options={dropDownItem}
        disableCloseOnSelect
        onChange={(event, newValue, reason) => {
          onChange(event, newValue as Array<SelectOptions>, reason);
          setValue(newValue as Array<SelectOptions>);
        }}
        /* We needed to use Type any here , as any other type used over here,
          was resulting in linting erros and showing those erros on browser when component loaded */
        getOptionLabel={(option: any) => option.value}
        renderOption={(props, option: any, { selected }) => (
          <StyledAutocompleteLi {...props} aria-disabled={disableOptions(option)}>
            <StyledAutocompleteLibox>
              <Checkbox
                style={{
                  marginRight: 8,
                  height: '24px',
                  width: '24px',
                  color: '#646A7C',
                  borderRadius: '4px',
                  backgroundColor: '#fff',
                }}
                checked={selected}
                icon={<StyledCheckboxIcon src={window.location.origin + '/res/hd-ui-kit-images/unchecked-checkbox.svg'} alt={'unchecked'} />}
                checkedIcon={<StyledCheckboxIcon src={window.location.origin + '/res/hd-ui-kit-images/checked-checkbox.svg'} alt={'checked'} />}
              />
              <StyledOption>{option.value || ''}</StyledOption>
            </StyledAutocompleteLibox>
          </StyledAutocompleteLi>
        )}
        renderInput={(params) => <StyledTextField {...params} error={isError} placeholder={params.InputProps.startAdornment === undefined ? 'Select' : ''} />}
        {...extraProps}
      />
      {isError && (
        <FormHelperTextStyled error={true}>
          <ErrorIcon src={window.location.origin + '/res/hd-ui-kit-images/warning-icon.svg'} />
          {errorMsg}
        </FormHelperTextStyled>
      )}
    </>
  );
}
