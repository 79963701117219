import { Box, Container, styled, Typography, useMediaQuery, useTheme, Divider, CssBaseline } from '@mui/material';
import React, { useState, useEffect, useContext } from 'react';
import Loader from '../../commons/Loader';
import Header from '../../commons/Header';
import Footer from '../../commons/Footer';
import HDButton from '../../hd-ui-kit/HDButton';
import { ContainerStyle, contentWrapStyle, styledCardWrappers, titleStyle, fieldStyle, btnStyle, Stepper, dividerStyle, borderFormWrap, subTitleStyle } from '../auth/NewSignupStyles';
import ArrowForwardSharpIcon from '@mui/icons-material/ArrowForwardSharp';
import HDDropDown from '../../hd-ui-kit/HDDropDown';
import formData from '../../utils/form-data';
import { useNavigate } from 'react-router-dom';
import { backgroundTopRight, backgroundTopRightDark, getDynamicCss } from '../../commons/commonFunctions';
import HDMultiSelectDropdown from '../../hd-ui-kit/HDMultiSelect/HDMultiSelectDropdown';
import Api from '../../Api';
import { globalContext } from '../../store';
import { validateFields, validationOptions } from '../../ValidationSchema';

interface PayloadObjectTypes {
  employer_type: string;
  salary_range: string;
  annual_base_salary_percent: string;
  compensation_package_attributes: any[];
  granted_frequency_of_compensation: string;
  incentives_granted_last_year: string;
}

const CompensationDetails = () => {
  const theme = useTheme();
  const themeStyle = theme.palette.mode;
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  const { dispatch, globalState } = useContext(globalContext);
  const [loading, setLoading] = useState<boolean>(false);
  let navigate = useNavigate();

  const [employerType, setEmployerType] = useState<string>('');
  const [salaryRange, setSalaryRange] = useState<string>('');
  const [annualBaseSalaryPercent, setAnnualBaseSalaryPercent] = useState<string>('');
  const [compensationPackageAttributes, setCompensationPackageAttributes] = useState<any>([]);
  const [grantedFrequencyOfCompensation, setGrantedFrequencyOfCompensation] = useState<any>('');
  const [incentivesGrantedLastYear, setIncentivesGrantedLastYear] = useState<string>('');
  const isMobileOrTablet = isMobile || isTablet;
  const [errors, setErrors] = useState<any>({});

  const ArrowForwardSharpIconStyled = styled(ArrowForwardSharpIcon)(() => ({
    color: themeStyle == 'light' ? '#66FCFC' : '#18768C',
  }));

  const subTitle = {
    fontFamily: 'Avenir',
    fontStyle: 'normal',
    fontWeight: 300,
    fontSize: '16px',
    lineHeight: '24px',
    textAlign: 'left',
    color: '#334255',
    marginTop: '10px',
  };

  const getBackgroundImage = (theme:any, isMobileOrTablet:any) =>{
    const mode = theme.palette.mode === 'light' ? 'Light' : 'Dark';
    const deviceType = isMobileOrTablet ? 'Mobile' : '';
    return `url(../res/NewPasswordBG${mode}${deviceType}.svg)`;
  }

  const BackImage = styled(Box)(() => ({
    display: 'block',
    position: 'absolute',
    width: '100%',
    height: '100%',
    content: '""',
    backgroundImage: getBackgroundImage(theme, isMobileOrTablet),
    backgroundRepeat: 'no-repeat',
    backgroundPosition: isMobileOrTablet ? 'right bottom' : 'right top',
    right: isMobileOrTablet ? 0 : '-30px',
    top: isMobileOrTablet ? 'auto' : 0,
    bottom: 0,
    backgroundSize: getDynamicCss(isMobile,'90%', isTablet, '63%', '52%')
  }));

  useEffect(() => {
    if (globalState?.account) {
      if (globalState?.account?.step_completed <= 4) {
        switch (globalState?.account?.step_completed) {
          case 1:
            navigate('/create-profile/experience');
            break;
          case 2:
            navigate('/create-profile/compensation-details');
            break;
          case 3:
            navigate('/create-profile/personal-details');
            break;
          case 4:
            navigate('/home');
            break;
        }
      }
    }
  }, [globalState]);

  function saveObject() {
    setLoading(true);
    setErrors({});
    const employerTypeMsg = validateFields.validate({ dropDown: employerType }, validationOptions)?.error?.details[0]?.message;
    const salaryRangeMsg = validateFields.validate({ dropDown: salaryRange }, validationOptions)?.error?.details[0]?.message;
    const annualBaseSalaryPercentMsg = validateFields.validate({ dropDown: annualBaseSalaryPercent }, validationOptions)?.error?.details[0]?.message;
    const grantedFrequencyOfCompensationMsg = validateFields.validate({ dropDown: grantedFrequencyOfCompensation }, validationOptions)?.error?.details[0]?.message;
    const incentivesGrantedLastYearMsg = validateFields.validate({ dropDown: incentivesGrantedLastYear }, validationOptions)?.error?.details[0]?.message;
    let compensationPackageAttributesMsg;
    if (compensationPackageAttributes.length === 0) {
      compensationPackageAttributesMsg = 'This field may not be blank.';
    }
    if (employerTypeMsg || salaryRangeMsg || annualBaseSalaryPercentMsg || compensationPackageAttributesMsg || grantedFrequencyOfCompensationMsg || incentivesGrantedLastYearMsg) {
      setErrors({
        employer_type: employerTypeMsg,
        salary_range: salaryRangeMsg,
        annual_base_salary_percent: annualBaseSalaryPercentMsg,
        compensation_package_attributes: compensationPackageAttributesMsg,
        granted_frequency_of_compensation: grantedFrequencyOfCompensationMsg,
        incentives_granted_last_year: incentivesGrantedLastYearMsg,
      });
      setLoading(false);
      return;
    }

    const payload: PayloadObjectTypes = {
      employer_type: employerType,
      salary_range: salaryRange,
      annual_base_salary_percent: annualBaseSalaryPercent,
      compensation_package_attributes: compensationPackageAttributes,
      granted_frequency_of_compensation: grantedFrequencyOfCompensation,
      incentives_granted_last_year: incentivesGrantedLastYear,
    };

    Api.saveCompensation(payload)
      .then((res) => {
        onApiSuccess(res);
      })
      .catch((err) => {
        setLoading(false);
      });
  }

  const onApiSuccess = (res: any) => {
    if (res?.success) {
      // navigate to the next form
      setLoading(false);
      getAccountDataApi();
      navigate('/create-profile/personal-details');
    } else {
      setLoading(false);
    }
  };

  const getAccountDataApi = async () => {
    await Api.getAccount()
      .then((res) => {
        let data = res.response?.data;
        dispatch({ type: 'SET_ACCOUNT', payload: data });
      })
      .catch((err) => {
        console.log('err', err);
      });
  };

  return (
    <>
      <CssBaseline />
      {loading && <Loader />}
      <Header />
      <Box sx={themeStyle === 'light' ? { ...backgroundTopRight, display: 'grid' } : { ...backgroundTopRightDark, display: 'grid' }}>
        <Container maxWidth="lg" sx={ContainerStyle(isMobile, isTablet)}>
          <BackImage></BackImage>
          <Box sx={contentWrapStyle(isMobile, isTablet)}>
            {Stepper(2)}
            <Box sx={styledCardWrappers(isMobile, isTablet)}>
              <Typography sx={titleStyle(themeStyle)}>Share some details about your compensation.</Typography>
              <Typography sx={subTitle}>
                This information is being collected to improve the services we provide to you. The answers you provide will be anonymized and aggregated and will not be used for recruitment purposes. All answers should reflect your current or most recent employer.
              </Typography>

              <Box sx={borderFormWrap(themeStyle)}>
                <Typography sx={subTitleStyle(themeStyle)}>Annual Salary & Incentive</Typography>
                <Divider sx={dividerStyle(themeStyle)} />
                <Box sx={fieldStyle}>
                  <HDDropDown
                    fieldLabel={'Is your employer a privately owned or public company?'}
                    value={employerType === null ? '' : employerType}
                    dropDownItem={formData.EmployerTypeList}
                    fullWidth={true}
                    disabled={false}
                    defaultValue=""
                    onChange={({ target }) => {
                      setEmployerType(target.value);
                    }}
                    error={errors?.employer_type}
                    errorMsg={errors?.employer_type ? errors.employer_type : null}
                    id="employerType"
                  />
                </Box>
                <Box sx={fieldStyle}>
                  <HDDropDown
                    fieldLabel={'What salary range best describes your base salary level?'}
                    value={salaryRange === null ? '' : salaryRange}
                    dropDownItem={formData.SalaryRangeList}
                    fullWidth={true}
                    disabled={false}
                    defaultValue=""
                    onChange={({ target }) => {
                      setSalaryRange(target.value);
                    }}
                    error={errors?.salary_range}
                    errorMsg={errors?.salary_range ? errors.salary_range : null}
                    id="salaryRange"
                  />
                </Box>

                <Box sx={fieldStyle}>
                  <HDDropDown
                    fieldLabel={'What is your annual target incentive as a percentage of annual base salary?'}
                    value={annualBaseSalaryPercent === null ? '' : annualBaseSalaryPercent}
                    dropDownItem={formData.AnnualBaseSalaryPercentList}
                    fullWidth={true}
                    disabled={false}
                    defaultValue=""
                    onChange={({ target }) => {
                      setAnnualBaseSalaryPercent(target.value);
                    }}
                    error={errors?.annual_base_salary_percent}
                    errorMsg={errors?.annual_base_salary_percent ? errors.annual_base_salary_percent : null}
                    id="annualBaseSalaryPercent"
                  />
                </Box>
              </Box>

              <Box sx={borderFormWrap(themeStyle)}>
                <Typography sx={subTitleStyle(themeStyle)}>Long-Term Incentives</Typography>
                <Divider sx={dividerStyle(themeStyle)} />
                <Box sx={fieldStyle}>
                  <HDMultiSelectDropdown
                    label={'Do you receive any of the following as part of your compensation package?'}
                    selectValue={compensationPackageAttributes}
                    dropDownItem={formData.CompensationPackageAttributesList}
                    onChange={(event: any, newValue: any) => {
                      const selectedValues = newValue.map((option: any) => option.value);
                      setCompensationPackageAttributes(selectedValues);
                    }}
                    isError={errors?.compensation_package_attributes ? true : false}
                    errorMsg={errors?.compensation_package_attributes ? errors.compensation_package_attributes : null}
                  />
                </Box>
                <Box sx={fieldStyle}>
                  <HDDropDown
                    fieldLabel={'How frequently are these granted?'}
                    value={grantedFrequencyOfCompensation === null ? '' : grantedFrequencyOfCompensation}
                    dropDownItem={formData.GrantedFrequencyOfCompensationList}
                    fullWidth={true}
                    disabled={false}
                    defaultValue=""
                    onChange={({ target }) => {
                      setGrantedFrequencyOfCompensation(target.value);
                    }}
                    error={errors?.granted_frequency_of_compensation}
                    errorMsg={errors?.granted_frequency_of_compensation ? errors.granted_frequency_of_compensation : null}
                    id="grantedFrequencyOfCompensation"
                  />
                </Box>

                <Box sx={fieldStyle}>
                  <HDDropDown
                    fieldLabel={'What is the annual face value (number of shares X stock price on grant date) of all long-term incentive granted to you in the last year?'}
                    value={incentivesGrantedLastYear === null ? '' : incentivesGrantedLastYear}
                    dropDownItem={formData.IncentivesGrantedLastYearList}
                    fullWidth={true}
                    disabled={false}
                    defaultValue=""
                    onChange={({ target }) => {
                      setIncentivesGrantedLastYear(target.value);
                    }}
                    error={errors?.incentives_granted_last_year}
                    errorMsg={errors?.incentives_granted_last_year ? errors.incentives_granted_last_year : null}
                    id="incentivesGrantedLastYear"
                  />
                </Box>
              </Box>

              <HDButton
                variant={'contained'}
                endIcon={<ArrowForwardSharpIconStyled />}
                text={'Next'}
                onClick={() => {
                  saveObject();
                }}
                sx={btnStyle(themeStyle)}
                showAnimation={true}
              />
            </Box>
          </Box>
        </Container>
      </Box>
      <Footer />
    </>
  );
};

export default CompensationDetails;
