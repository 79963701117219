import React, { useEffect, useState } from 'react';
import { Box, Divider, Typography, useMediaQuery, useTheme } from '@mui/material';
import ReactGA from 'react-ga4';

import {
  headerStyle,
  subHeaderStyle,
  pageWrapper,
  titleUnderline,
  btnStyle,
  btnIconStyle,
  btnWrapper,
  textFieldStyle,
  infoBoxStyle,
  titleDivider
} from './styles';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import HDTextField from '../../../hd-ui-kit/HDTextField';
import HDDropDown from '../../../hd-ui-kit/HDDropDown';
import HDButton from '../../../hd-ui-kit/HDButton';
import { HDRadio } from '../../../hd-ui-kit/HDRadio';
import HDDatePicker from '../../../hd-ui-kit/HDDatePicker';
import HDMultipleTextField from '../../../hd-ui-kit/HDMultipleTextField';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import HDCitySelection from '../../../hd-ui-kit/HDCitySelection/HDCitySelection';
import HDCheckBox from '../../../hd-ui-kit/HDCheckBox/HDCheckBox';
import Api from '../../../Api';
import formData from '../../../utils/form-data';
import { validateFields, validationOptions } from '../../../ValidationSchema';
import { reactGAEvents } from '../../../commons/commonFunctions';
import HDSpinner from '../../../hd-ui-kit/HDSpinner/HDSpinner';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(timezone);

function Experience(props: any) {
  const theme = useTheme();
  const [boardAffiliations, setBoardAffiliations] = useState<any>(['']);
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const themeMode = theme.palette.mode;
  const [data, setData] = useState(null);
  const [location, setLocation] = useState('');
  const [company, setCompany] = useState('');
  const [companySize, setCompanySize] = useState('');
  const [isCurrent, setIsCurrent] = useState(true);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [startDateUnformatted, setStartDateUnformatted] = React.useState(null);
  const [endDateUnformatted, setEndDateUnformatted] = React.useState(null);
  const [jobtitle, setJobTitle] = useState('');
  const [jobLevel, setJobLevel] = useState('');
  const [jobFunction, setJobFunction] = useState('');
  const [industry, setIndustry] = useState('');
  const [industrySector, setIndustrySector] = useState('');
  const [errors, setErrors] = React.useState<any>({});
  const [btnDisable, setBtnDisable] = useState(false);
  const [activelyLooking, setActivelyLooking] = useState<boolean>(false);

  useEffect(() => {
    if (props?.data) {
      setData(props?.data);
      setFormDataFromContext(props?.data);
    }
  }, [props?.data]);

  const getUnformattedString = (str: string) => {
    if (str) {
      return dayjs(str).utc().format();
    }
    return null;
  };

  const setFormDataFromContext = (data: { [key: string]: React.SetStateAction<string> | React.SetStateAction<any>; }) => {
    setLocation(data['current_location']);
    setCompany(data['company_name']);
    setCompanySize(data['company_size']);
    setIsCurrent(data['is_current']);
    setJobTitle(data['job_title']);
    setActivelyLooking(data['actively_looking']);
    setJobLevel(data['job_level']);
    setJobFunction(data['job_function']);
    setIndustry(data['industry']);
    setIndustrySector(data['industry_sector']);
    setBoardAffiliations(data?.['board_affiliations']?.length ? data?.['board_affiliations'] : ['']);

    // convert month to MM/YYYY
    let startDateF = formatDateString(data['start_date']);
    let endDateF = formatDateString(data['end_date']);
    setStartDate(startDateF);
    setEndDate(endDateF);
    setStartDateUnformatted(new Date(getUnformattedString(data['start_date'])));
    if (data['end_date']) {
      setEndDateUnformatted(new Date(getUnformattedString(data['end_date'])));
    }
  };

  const formatDateString = (str: string) => {
    if (str) {
      return str.substring(5, 7) + '/' + str.substring(0, 4);
    }
    return null;
  };

  const monthFomat = (month: any) => {
    let newMonth = month;
    if (month.toString().length == 1) {
      newMonth = month.toString().padStart(2, '0');
    }
    return newMonth;
  };

  const saveExperienceForm = (event: { preventDefault: () => void; }) => {
    setBtnDisable(true);
    event.preventDefault();
    props.onSuccess(false);
    reactGAEvents('my-account', 'save');
    const chkForTagsRegValue = '^([^<]|a z|A Z|1 9|(.\\.))*$'
    let chkForTagsRegEx = new RegExp(chkForTagsRegValue);

    const industryCheck = (industry && industry !== 'Prefer not to respond') ? industry : '';
    const industrySectorCheck = (industrySector && industrySector !== 'Prefer not to respond') ? industry : '';
    const jobTitleMsg = validateFields.validate({ name: jobtitle }, validationOptions)?.error?.details[0]?.message;
    const jobFunctionMsg = validateFields.validate({ dropDown: jobFunction }, validationOptions)?.error?.details[0]?.message;
    const jobLevelMsg = validateFields.validate({ dropDown: jobLevel }, validationOptions)?.error?.details[0]?.message;
    const locationMsg = validateFields.validate({ textField: location }, validationOptions)?.error?.details[0]?.message;
    const companyMsg = validateFields.validate({ name: company }, validationOptions)?.error?.details[0]?.message;
    const companySizeMsg = validateFields.validate({ dropDown: companySize }, validationOptions)?.error?.details[0]?.message;
    const industryMsg = validateFields.validate({ dropDown: industryCheck }, validationOptions)?.error?.details[0]?.message;
    const industrySectorMsg = validateFields.validate({ dropDown: industrySectorCheck }, validationOptions)?.error?.details[0]?.message;
    const monthYearRe = /^\d{2}\/\d{4}$/;
    const startDateIsValid = monthYearRe.test(startDate);
    const endDateIsValid = monthYearRe.test(endDate);
    const currentYear = new Date().getFullYear();
    const startDateYear = startDate?.split('/')?.[1];
    const endDateYear = endDate ? endDate?.split('/')?.[1] : null;
    const startDateParts = startDate ? startDate.split('/') : null;
    const endDateParts = endDate ? endDate.split('/') : null;
    const startMonth = startDateParts ? parseInt(startDateParts?.[0]) : null;
    const endMonth = endDateParts ? parseInt(endDateParts?.[0]) : null;
    let dateMsg: any = { start_date: '', end_date: '' };
    let boardAffiliationsMsg: any = {};
    let board_affiliations_errors: any = {};


    if (!isCurrent && (!startDateIsValid && !endDateIsValid)) {
      dateMsg.start_date = 'Date must be in the following format: MM/YYYY';
      dateMsg.end_date = 'Date must be in the following format: MM/YYYY';
    } else if (!isCurrent && !startDateIsValid) {
      dateMsg.start_date = 'Date must be in the following format: MM/YYYY';
    } else if (!isCurrent && !endDateIsValid) {
      dateMsg.end_date = 'Date must be in the following format: MM/YYYY';
    } else if (isCurrent && !startDateIsValid) {
      dateMsg.start_date = 'Date must be in the following format: MM/YYYY';
    } else if (endDateYear && currentYear < parseInt(endDateYear)) {
      dateMsg.end_date = 'End date can not be in the future';
    } else if (currentYear < parseInt(startDateYear)) {
      dateMsg.start_date = 'Start date can not be in the future';
    } else if (!isCurrent && (endDateYear < parseInt(startDateYear)) || (endDateYear === startDateYear && startMonth > endMonth)) {
      dateMsg.end_date = 'End date should be greater than start date';
    }


    if (boardAffiliations.length !== [...new Set(boardAffiliations)].length) {
      boardAffiliationsMsg.non_field_errors = 'No duplicates are allowed';
    }

    if (boardAffiliations[0] !== '' && boardAffiliations?.length !== 1) {
      if (boardAffiliations.length) {
        boardAffiliations.forEach((field: any, idx: number) => {
          if (!field) {
            board_affiliations_errors[`${idx}`] = 'This field may not be blank.';
          } else if (!chkForTagsRegEx.test(field)) {
            board_affiliations_errors[`${idx}`] = 'Text should not contain any HTML Tags / Special characters';
          }
        });
        if (Object.keys(board_affiliations_errors).length) {
          boardAffiliationsMsg = board_affiliations_errors;
        }
      }
    }

    if (boardAffiliations?.[0] !== '' && boardAffiliations?.length === 1) {
      if (!chkForTagsRegEx.test(boardAffiliations[0])) {
        boardAffiliationsMsg = { 0: 'Text should not contain any HTML Tags / Special characters' };
      }
    }

    if (jobTitleMsg || dateMsg.start_date || dateMsg.end_date || jobFunctionMsg || jobLevelMsg || locationMsg || companyMsg || companySizeMsg || industryMsg || industrySectorMsg || Object.keys(boardAffiliationsMsg).length) {
      setErrors({
        job_title: jobTitleMsg,
        start_date: dateMsg.start_date,
        end_date: dateMsg.end_date,
        job_function: jobFunctionMsg,
        job_level: jobLevelMsg,
        current_location: locationMsg,
        company_name: companyMsg,
        company_size: companySizeMsg,
        industry: industryMsg,
        industry_sector: industrySectorMsg,
        board_affiliations: boardAffiliationsMsg,
      });
      setBtnDisable(false);
      return;
    }

    let boardPayload = boardAffiliations.length === 1 && boardAffiliations[0] === ''
      ? []
      : boardAffiliations;

    let payload = {
      current_location: location,
      company_name: company,
      company_size: companySize,
      is_current: Boolean(isCurrent),
      job_title: jobtitle,
      job_level: jobLevel,
      job_function: jobFunction,
      industry: industry,
      industry_sector: industrySector,
      board_affiliations: boardPayload,
      start_date: startDate,
      end_date: isCurrent ? null : endDate,
      actively_looking: activelyLooking
    };

    if (data) {
      setErrors({});
      Api.updateExperience(payload, data['id'] as never)
        .then((res) => {
          onApiSuccess(res, 'Edit');
        })
        .catch((err) => {
          console.log(err);
          setBtnDisable(false);
        });
    } else {
      setErrors({});
      Api.saveExperience(payload)
        .then((res) => {
          onApiSuccess(res, 'Save');
        })
        .catch((err) => {
          console.log(err);
          setBtnDisable(false);
        });
    }
  };

  const onApiSuccess = (res: any, actItem: string) => {
    if (res?.success) {
      ReactGA.event({
        category: 'Profile Edit - Current or most recent experience',
        action: actItem,
      });
      props.onSuccess(true);
      setBtnDisable(false);
      window.scrollTo(0, 0);
    } else {
      const { data } = res;
      setBtnDisable(false);
      setErrors(data);
    }
  };

  const sxStyleMarginBottom = { marginBottom: '20px' };

  return (
    <Box sx={pageWrapper(isMobile, themeMode)}>
      <Typography sx={headerStyle(themeMode)}>Current or most recent experience</Typography>
      <Divider sx={titleUnderline(themeMode)} />

      <Box sx={infoBoxStyle(themeMode, 'top')}>
        <Typography sx={subHeaderStyle(themeMode)}>About the position</Typography>
        <Divider sx={titleDivider(themeMode)} />

        <HDTextField
          type={'text'}
          placeholder={'Job title'}
          id={'jobTitle'}
          lblText={'Job title'}
          sx={textFieldStyle}
          value={jobtitle}
          onClick={() => {
            reactGAEvents('my-account', 'job-title');
          }}
          onChange={({ target }: any) => {
            setJobTitle(target.value);
          }}
          error={errors?.job_title}
          errorMsg={errors?.job_title ? errors.job_title : null}
        />

        <Box sx={textFieldStyle}>
          <HDRadio
            label={'Current'}
            name='current'
            disabled={false}
            showBackground={false}
            hasError={errors?.is_current}
            checked={isCurrent}
            value={isCurrent}
            onChange={({ target }: any) => {
              reactGAEvents('my-account', 'current');
              setIsCurrent(target.value);
              setEndDate(null);
              setEndDateUnformatted(null);
            }}
            id='current-value'
          />

          <HDRadio
            label={'Previous'}
            name='previous'
            disabled={false}
            showBackground={false}
            hasError={errors?.is_current}
            checked={!isCurrent}
            value={!isCurrent}
            onChange={({ target }: any) => {
              reactGAEvents('my-account', 'previous');
              setIsCurrent(!target.value);
            }}
            id='previous-value'
          />
        </Box>

        <HDDatePicker
          views={['month', 'year']}
          id='start-date'
          lblText='Start Date (MM / YYYY)'
          inputFormat={'MM/yyyy'}
          sx={textFieldStyle}
          value={startDateUnformatted}
          onChange={(e: any) => {
            reactGAEvents('my-account', 'start-date');
            if (e?.c?.year === undefined || e?.c?.month === undefined) {
              setStartDate('');
            }
            else if (e) {
              setStartDate(`${monthFomat(e?.c?.month)}/${e?.c?.year}`);
              setStartDateUnformatted(new Date(e));
            } else {
              setStartDate('');
            }
          }}
          error={errors?.start_date}
          errorMsg={errors?.start_date ? errors.start_date : null}
        />

        {!isCurrent && (
          <Box sx={{ marginTop: '36px' }}>
            <HDDatePicker
              views={['month', 'year']}
              id='end-date'
              lblText='End Date (MM / YYYY)'
              inputFormat={'MM/yyyy'}
              sx={textFieldStyle}
              value={endDateUnformatted}
              onChange={(e: any) => {
                reactGAEvents('my-account', 'end-date');
                if (e?.c?.year === undefined || e?.c?.month === undefined) {
                  setEndDate('');
                }
                else if (e) {
                  setEndDate(`${monthFomat(e?.c?.month)}/${e?.c?.year}`);
                  setEndDateUnformatted(new Date(e));
                } else {
                  setEndDate('');
                }
              }}
              error={errors?.end_date}
              errorMsg={errors?.end_date ? errors.end_date : null}
            />
          </Box>
        )}

        <Box sx={{ marginTop: '36px' }}>
          <HDCheckBox
            id='actively-looking'
            labelTxt={' I am actively looking for a new position'}
            checked={activelyLooking}
            value={activelyLooking}
            onChange={({ target }: any) => {
              setActivelyLooking(target.checked);
            }}
          />
        </Box>

        <Box sx={{ marginTop: '36px' }}>
          <Box sx={sxStyleMarginBottom}>
            <HDDropDown
              fieldLabel={'Function'}
              value={jobFunction}
              dropDownItem={formData.jobFunctionMenu.map((ci) => ({ keyName: ci, keyValue: ci }))}
              error={errors?.job_function}
              errorMsg={errors?.job_function ? errors.job_function : null}
              onChange={({ target }: any) => {
                reactGAEvents('my-account', `function-${target.value}`);
                setJobFunction(target.value);
              }}
              id='function'
            />
          </Box>
        </Box>
        <Box sx={sxStyleMarginBottom}>
          <HDDropDown
            fieldLabel={'Job level'}
            value={jobLevel}
            dropDownItem={formData.jobLevelMenu.map((ci) => ({ keyName: ci, keyValue: ci }))}
            error={errors?.job_level}
            errorMsg={errors?.job_level ? errors.job_level : null}
            onChange={({ target }: any) => {
              reactGAEvents('my-account', `job-level-${target.value}`);
              setJobLevel(target.value);
            }}
            id='job-level'
          />
        </Box>
        <HDCitySelection
          placeholder='City where you work'
          lblText={'City where you work'}
          id='city-where-you-work'
          label='City where you work'
          type={'text'}
          required={true}
          value={location}
          onChange={(value: any) => {
            reactGAEvents('my-account', 'work-city');
            setLocation(String(value));
          }}
          error={errors?.current_location}
          errorMsg={errors?.current_location ? errors.current_location : null}
          disabled={false}
          size={''}
        />
      </Box>

      <Box sx={infoBoxStyle(themeMode)}>
        <Typography sx={subHeaderStyle(themeMode)}>About the company</Typography>
        <Divider sx={titleDivider(themeMode)} />

        <HDTextField
          type={'text'}
          placeholder={'Company name'}
          id={'companyName'}
          lblText={'Company name'}
          sx={textFieldStyle}
          value={company}
          onClick={() => {
            reactGAEvents('my-account', 'company-name');
          }}
          onChange={({ target }: any) => {
            setCompany(target.value);
          }}
          error={errors?.company_name}
          errorMsg={errors?.company_name ? errors.company_name : null}
        />

        <Box sx={sxStyleMarginBottom}>
          <HDDropDown
            fieldLabel={'Company size'}
            dropDownItem={formData.companySize}
            fullWidth={true}
            disabled={false}
            defaultValue=""
            value={companySize}
            onChange={({ target }: any) => {
              setCompanySize(target.value)
              reactGAEvents('my-account', 'company-size')
            }}
            error={errors?.company_size}
            errorMsg={errors?.company_size ? errors.company_size : null}
            id='company-size'
          />
        </Box>


        <Box sx={sxStyleMarginBottom}>
          <HDDropDown
            fieldLabel={'Industry'}
            value={industry}
            onChange={({ target }: any) => {
              reactGAEvents('my-account', `industry-${target.value}`);
              setIndustry(target.value);
              setIndustrySector('');
            }}
            error={errors?.industry}
            errorMsg={errors?.industry ? errors.industry : null}
            dropDownItem={formData.industryMenu.map((ci) => ({ keyName: ci, keyValue: ci }))}
            id='industry'
          />
        </Box>

        <Box sx={sxStyleMarginBottom}>
          <HDDropDown
            value={industrySector}
            onChange={({ target }: any) => {
              reactGAEvents('my-account', `sector-${target.value}`);
              setIndustrySector(target.value);
            }}
            error={errors?.industry_sector}
            errorMsg={errors?.industry_sector ? errors.industry_sector : null}
            dropDownItem={formData.industrySector(industry).map((ci) => ({ keyName: ci, keyValue: ci }))}
            fieldLabel={'Industry sector'}
            id='industry-sector'
          />
        </Box>
      </Box>

      <Box sx={infoBoxStyle(themeMode)}>
        <Typography sx={subHeaderStyle(themeMode)}>Your board affiliations</Typography>
        <Divider sx={titleDivider(themeMode)} />

        <HDMultipleTextField
          value={boardAffiliations}
          setterFn={setBoardAffiliations}
          buttonProps={{
            text: 'Add more board affiliations',
            startIcon: <AddCircleOutlineRoundedIcon />,
          }}
          error={errors?.board_affiliations}
          errorMsg={errors?.board_affiliations?.non_field_errors ? errors.board_affiliations?.non_field_errors : errors.board_affiliations}
        />
      </Box>

      <Box sx={btnWrapper}>
        <HDButton text={'Save'} disabled={btnDisable} endIcon={btnDisable ? <HDSpinner size={'la-sm'} /> : <ArrowForwardIcon sx={btnIconStyle(themeMode)} />} variant={'contained'} sx={btnStyle(themeMode)} onClick={saveExperienceForm} showAnimation={true} />
      </Box>
    </Box>
  );
}

export default Experience;
