import React, { useEffect, useState } from 'react';
import { Box, Divider, Typography, useMediaQuery, useTheme } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ReactGA from 'react-ga4';

import { headerStyle, subHeaderStyle, pageWrapper, titleUnderline, btnStyle, btnIconStyle, btnWrapper, textFieldStyle, infoBoxStyle, titleDivider } from './styles';
import HDTextField from '../../../hd-ui-kit/HDTextField';
import HDDropDown from '../../../hd-ui-kit/HDDropDown';
import HDButton from '../../../hd-ui-kit/HDButton';
import HDCheckBox from '../../../hd-ui-kit/HDCheckBox/HDCheckBox';
import formData from '../../../utils/form-data';
import Api from '../../../Api';
import HDMobileField from '../../../hd-ui-kit/HDMobileField';
const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance();
import { validateFields, validationOptions } from '../../../ValidationSchema';
import { reactGAEvents } from '../../../commons/commonFunctions';
import { formatTimezoneID, getTimeZoneOptions } from '../../../commons/timezones';
import HDSpinner from '../../../hd-ui-kit/HDSpinner/HDSpinner';

interface PayloadObjectTypes {
  first_name: any;
  last_name: any;
  mobile_number: string;
  mobile_country_code: string;
  text_opt_in: boolean;
  country: string;
  gender: string;
  ethnicity: string;
  lgbtq: string;
  diversity: string;
  linkedin_url: string;
  iana_timezone: string;
}

function PersonalDetails({ data, onSuccess, timeZoneValue, linkedInURLRef }: any) {
  const theme = useTheme();
  const themeMode = theme.palette.mode;
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const oauthExtraData = JSON.parse(sessionStorage.getItem('oauth_extra_data'));
  let oauthFirstName = oauthExtraData?.first_name?.localized?.en_US;
  let oauthLastName = oauthExtraData?.last_name?.localized?.en_US;
  const [firstname, setFirstname] = useState<string>(oauthFirstName || '');
  const [lastname, setLastname] = useState<string>(oauthLastName || '');
  const [phone, setPhone] = useState<string>('');
  const [countryCode, setCountryCode] = useState<string>('us');
  const [textOpt, setTextOpt] = useState<boolean>(false);
  const [linkedInUrl, setLinkedInUrl] = useState<string>('');
  const [country, setCountry] = useState<string>('');
  const [gender, setGender] = useState<string>('');
  const [ethnicity, setEthnicity] = useState<string>('');
  const [lgbtq, setLgbtq] = useState<string>('');
  const [diversity, setDiversity] = useState<string>('');
  const [errors, setErrors] = useState<any>({});
  const [timezone, setTimeZone] = useState({ 'value': '', 'label': '' });
  const [timezoneOptions, setTimeZoneOptions] = useState(getTimeZoneOptions('Other'));
  const [btnDisable, setBtnDisable] = useState(false);


  useEffect(() => {
    setTimeZoneOptions(getTimeZoneOptions(country || 'Other'));
    if (country === 'United States of America' || country === 'United Kingdom') {
      return;
    } else if (country === 'Canada' || country === 'Australia') {
      setLgbtq('');
    } else if (country === '') {
      setLgbtq('');
      setDiversity('');
    } else {
      setLgbtq('');
    }
    return;
  }, [country]);

  const setFormDataFromContext = (data: { [key: string]: React.SetStateAction<string> }) => {
    if (!data) {
      setCountryCode('us');
      return;
    }
    setFirstname(data['first_name']);
    setLastname(data['last_name']);
    setPhone(data['mobile_number']);
    setCountryCode(data['mobile_country_code']);
    setTextOpt(data['text_opt_in'] as unknown as boolean);
    setLinkedInUrl(data['linkedin_url']);
    setCountry(data['country']);
    setGender(data['gender']);
    setEthnicity(data['ethnicity']);
    setLgbtq(data['lgbtq']);
    setDiversity(data['diversity']);
  };

  useEffect(() => {
    if (data) {
      setFormDataFromContext(data);
    } else {
      setCountryCode('us');
    }
    if (typeof timeZoneValue === 'string') {
      setTimeZone({ 'value': timeZoneValue, 'label': formatTimezoneID(timeZoneValue) });
    } else {
      setTimeZone(timeZoneValue);
    }
  }, [data, timeZoneValue]);

  const onSuccessSave = () => {
    ReactGA.event({
      category: 'Profile Edit - Personal Details',
      action: 'Save',
    });
    onSuccess(true);
    window.scrollTo(0, 0);
  };

  const validate = () => {
    let errorObject: any = {};

    // phone validation
    try {
      const number = phoneUtil.parse(phone, countryCode);
      const numberIsValid = phoneUtil.isValidNumberForRegion(number, countryCode);
      if (!numberIsValid) {
        let countryRegion;
        switch (countryCode) {
          case 'us':
            countryRegion = 'U.S.';
            break;
          case 'gb':
            countryRegion = 'UK';
            break;
          default:
            countryRegion = countryCode.toUpperCase();
            break;
        }
        errorObject['mobile_number'] = `Mobile number is not valid for ${countryRegion} region`;
      }
    } catch (e) {
      errorObject['mobile_number'] = 'The string supplied did not seem to be a phone number';
    }
    return errorObject;
  };

  const onSave = () => {
    setBtnDisable(true);
    reactGAEvents('my-account', 'save');
    onSuccess(false);
    setErrors({});
    const chkForTagsRegValue = '^[-a-zA-Z0-9?&//=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9?&//=]*)$'
    const chkForTagsRegEx = new RegExp(chkForTagsRegValue); //NOSONAR
    // const isValid = validate();
    // if (!isValid) {
    //   return;
    // }

    const firstNameMsg = validateFields.validate({ name: firstname }, validationOptions)?.error?.details[0]?.message;
    const lastNameMsg = validateFields.validate({ name: lastname }, validationOptions)?.error?.details[0]?.message;
    const mobileNumberMsg = validate();
    const countryMsg = validateFields.validate({ dropDown: country }, validationOptions)?.error?.details[0]?.message;
    const timeZoneMsg = validateFields.validate({ dropDown: timezone?.value ? timezone?.value : '' }, validationOptions)?.error?.details[0]?.message;
    const genderMsg = validateFields.validate({ dropDown: gender }, validationOptions)?.error?.details[0]?.message;

    if (firstNameMsg || lastNameMsg || mobileNumberMsg?.mobile_number || countryMsg || timeZoneMsg || genderMsg) {
      setErrors({
        first_name: firstNameMsg,
        last_name: lastNameMsg,
        mobile_number: mobileNumberMsg.mobile_number,
        country: countryMsg,
        timezone: timeZoneMsg,
        gender: genderMsg,
      });
      setBtnDisable(false);
      return;
    }

    if (linkedInUrl != '' && !chkForTagsRegEx.test(linkedInUrl)) {
      setErrors({ linkedin_url: 'Linked in URL should not contain HTML Tags/Special Characters' });
      setBtnDisable(false);
      return;
    }

    const defaultSelect = formData.declineSelectDefault;
    const payloadPhone = phone.replace(/\D/g, '');
    const payload: PayloadObjectTypes = {
      first_name: firstname,
      last_name: lastname,
      mobile_number: payloadPhone,
      mobile_country_code: countryCode,
      text_opt_in: textOpt,
      country: country,
      gender: gender || defaultSelect,
      ethnicity: ethnicity || defaultSelect,
      lgbtq: lgbtq || defaultSelect,
      diversity: diversity || defaultSelect,
      linkedin_url: linkedInUrl,
      iana_timezone: timezone?.value,
    };

    if (data) {
      Api.updatePersonalDetail(payload, data['id'] as never)
        .then((res) => {
          onApiSuccess(res);
        })
        .catch((err) => {
          console.log(err);
          setBtnDisable(false);
        });
    } else {
      // send data off to be saved
      Api.savePersonalDetail(payload)
        .then((res) => {
          onApiSuccess(res);
        })
        .catch((err) => {
          console.log(err);
          setBtnDisable(false);
        });
    }
  };

  const onApiSuccess = (res: any) => {
    if (res?.success) {
      // navigate to the next form
      setBtnDisable(false);
      onSuccessSave();
    } else {
      const { data } = res;
      setBtnDisable(false);
      setErrors(data);
    }
  };

  const sxStyleMarginBottom = { marginBottom: '20px' };

  return (
    <Box sx={pageWrapper(isMobile, themeMode)}>
      <Typography sx={headerStyle(themeMode)}>Personal Details</Typography>
      <Divider sx={titleUnderline(themeMode)} />

      <Box sx={infoBoxStyle(themeMode, 'top')}>
        <Typography sx={subHeaderStyle(themeMode)}>Contact Information</Typography>
        <Divider sx={titleDivider(themeMode)} />

        <HDTextField
          type={'text'}
          placeholder={'First name'}
          id={'firstName'}
          lblText={'First name'}
          sx={textFieldStyle}
          value={firstname}
          onClick={() => {
            reactGAEvents('my-account', 'first-name');
          }}
          onChange={({ target }: any) => {
            setFirstname(target.value);
          }}
          error={errors?.first_name}
          errorMsg={errors?.first_name ? errors.first_name : null}
        />

        <HDTextField
          type={'text'}
          placeholder={'Last name'}
          id={'lastName'}
          lblText={'Last name'}
          sx={textFieldStyle}
          value={lastname}
          onClick={() => {
            reactGAEvents('my-account', 'last-name');
          }}
          onChange={({ target }: any) => {
            setLastname(target.value);
          }}
          error={errors?.last_name}
          errorMsg={errors?.last_name ? errors.last_name : null}
        />
        <Box sx={textFieldStyle}>
          <HDMobileField
            id={'telephone'}
            label="Mobile Number"
            type="telephone"
            inputProps={{ style: { fontFamily: 'Avenir', padding: '16.5px 14px' } }}
            value={phone}
            defaultCountry={countryCode}
            onChange={(number: React.SetStateAction<string>, context: { countryCode: React.SetStateAction<string> }) => {
              reactGAEvents('my-account', 'mobile-number');
              setPhone(number);
              setCountryCode(context?.countryCode);
            }}
            required={true}
            error={errors?.mobile_number}
            errorMsg={errors?.mobile_number ? errors.mobile_number : null}
          />
        </Box>
        <Box sx={textFieldStyle}>
          <HDCheckBox
            labelTxt={"It's ok to call or text me about Heidrick job openings."}
            checked={textOpt}
            value={textOpt}
            disabled={false}
            onChange={({ target }: any) => {
              reactGAEvents('my-account', 'ok-to-contact-hs-job-openings');
              setTextOpt(target.checked);
            }}
            error={errors?.text_opt_in}
            id='its-ok-to-call'
          />
        </Box>
        <Box ref={linkedInURLRef}>
          <HDTextField
            type={'text'}
            placeholder={'LinkedIn URL(optional)'}
            id={'linkedInURL'}
            lblText={'LinkedIn URL'}
            sx={textFieldStyle}
            value={linkedInUrl}
            onClick={() => {
              reactGAEvents('my-account', 'linkedin-url');
            }}
            onChange={({ target }: any) => {
              setLinkedInUrl(target.value);
            }}
            error={errors?.linkedin_url}
            errorMsg={errors?.linkedin_url ? 'Enter a valid LinkedIn URL. URL needs to be linkedin.com/in/….' : null}
          />
        </Box>

        <Box sx={sxStyleMarginBottom}>
          <HDDropDown
            value={country}
            dropDownItem={formData.countryFormList.map((ci) => ({ keyName: ci, keyValue: ci }))}
            fieldLabel={'Country of Residence'}
            error={errors?.country}
            errorMsg={errors?.country ? errors.country : null}
            onChange={({ target }: any) => {
              reactGAEvents('my-account', `country-residence-${target.value}`);
              setTimeZone({ value: '', label: '' });
              setCountry(target.value);
            }}
            id='country-of-residence'
          />
        </Box>

        <HDDropDown
          dataTestId={'Time zone'}
          fieldLabel="Time zone"
          disabled={false}
          defaultValue=""
          dropDownItem={[
            { keyValue: 1, keyName: 'Select 1' },
            { keyValue: 2, keyName: 'Select 2' },
            { keyValue: 3, keyName: 'Select 3' },
          ]}
          isAutoComplete={true}
          autoCompleteOptions={timezoneOptions}
          value={timezone}
          onChange={(value) => {
            reactGAEvents('my-account', `time-zone-${value?.label}`);
            setTimeZone(value);
          }}
          error={errors?.timezone}
          errorMsg={errors?.timezone ? errors.timezone : null}
          id='time-zone'
        />
      </Box>

      <Box sx={infoBoxStyle(themeMode)}>
        <Typography sx={subHeaderStyle(themeMode)}>Additional information</Typography>
        <Divider sx={titleDivider(themeMode)} />
        <Box sx={sxStyleMarginBottom}>
          <HDDropDown
            fieldLabel={'Gender'}
            showToolTip={false}
            toolTipText={'Coming Soon!'}
            value={gender}
            dropDownItem={formData.genderMenu.map((ci) => ({ keyName: ci, keyValue: ci }))}
            error={errors?.gender}
            errorMsg={errors?.gender ? errors.gender : null}
            onChange={({ target }: any) => {
              reactGAEvents('my-account', `gender-${target.value}`);
              setGender(target.value);
            }}
            id='gender'
          />
        </Box>
        <Box sx={sxStyleMarginBottom}>
          <HDDropDown
            fieldLabel={'Ethnicity (optional)'}
            showToolTip={false}
            toolTipText={'Coming Soon!'}
            value={ethnicity}
            dropDownItem={formData.ethnicityMenu(country).map((ci) => ({ keyName: ci, keyValue: ci }))}
            onChange={({ target }: any) => {
              reactGAEvents('my-account', `ethnicity-${target.value}`);
              setEthnicity(target.value);
            }}
            id='ethnicity'
          />
        </Box>
        <Box sx={sxStyleMarginBottom}>
          <HDDropDown
            fieldLabel={'LGBTQ (optional)'}
            showToolTip={false}
            toolTipText={'Coming Soon!'}
            value={lgbtq}
            dropDownItem={formData.lgbtqMenu(country).map((ci) => ({ keyName: ci, keyValue: ci }))}
            error={errors?.lgbtq}
            errorMsg={errors?.lgbtq ? errors.lgbtq : null}
            onChange={({ target }: any) => {
              reactGAEvents('my-account', `lgbtq-${target.value}`);
              setLgbtq(target.value);
            }}
            disabled={!formData.lgbtqMenu(country).length}
            id='lgbtq'
          />
        </Box>
        <Box sx={sxStyleMarginBottom}>
          <HDDropDown
            fieldLabel={'Diversity, Non-Specific (optional)'}
            showToolTip={true}
            toolTipText={`Refers to individuals who identify as diverse but either do not fall into one of the above categories
           (could include but is not limited to veteran status or disability),
           or do not wish to share further information with the firm as to how they are diverse.`}
            value={diversity}
            dropDownItem={formData.diversityMenu(country).map((ci) => ({ keyName: ci, keyValue: ci }))}
            error={errors?.diversity}
            errorMsg={errors?.diversity ? errors.diversity : null}
            onChange={({ target }: any) => {
              reactGAEvents('my-account', `diversity-${target.value}`);
              setDiversity(target.value);
            }}
            id='diversity'
          />
        </Box>
      </Box>

      <Box sx={btnWrapper}>
        <HDButton text={'Save'} disabled={btnDisable} endIcon={btnDisable ? <HDSpinner size={'la-sm'} /> : <ArrowForwardIcon sx={btnIconStyle(themeMode)} />} variant={'contained'} sx={btnStyle(themeMode)} onClick={onSave} showAnimation={true} />
      </Box>
    </Box>
  );
}

export default PersonalDetails;
