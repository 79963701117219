import { Box, Container, styled, Typography, useMediaQuery, useTheme, Divider, CssBaseline } from '@mui/material';
import React, { useState, useEffect, useContext } from 'react';
import Loader from '../../commons/Loader';
import Header from '../../commons/Header';
import Footer from '../../commons/Footer';
import HDButton from '../../hd-ui-kit/HDButton';
import { ContainerStyle, contentWrapStyle, backImage, styledCardWrappers, titleStyle, fieldStyle, btnStyle, Stepper, dividerStyle, borderFormWrap, subTitleStyle } from '../auth/NewSignupStyles';
import HDTextField from '../../hd-ui-kit/HDTextField';
import ArrowForwardSharpIcon from '@mui/icons-material/ArrowForwardSharp';
import { HDRadio } from '../../hd-ui-kit/HDRadio';
import HDDatePicker from '../../hd-ui-kit/HDDatePicker';
import HDDropDown from '../../hd-ui-kit/HDDropDown';
import HDDocUpload from '../../hd-ui-kit/HDDocUpload';
import HDMultipleTextField from '../../hd-ui-kit/HDMultipleTextField';
import HDCheckBox from '../../hd-ui-kit/HDCheckBox/HDCheckBox';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import Api from '../../Api';
import { useNavigate, useOutletContext } from 'react-router-dom';
import formData from '../../utils/form-data';
import eventBus from '../../Eventbus';
import HDCitySelection from '../../hd-ui-kit/HDCitySelection/HDCitySelection';
import { validateFields, validationOptions } from '../../ValidationSchema';
import { reactGAEvents, backgroundTopRight, backgroundTopRightDark, ResponsiveMobileTabletValue, ResponsiveThemeValue } from '../../commons/commonFunctions';
import { globalContext } from '../../store';

const ProfessionalBackground = () => {
  const theme = useTheme();
  const themeStyle = theme.palette.mode;
  const [loading, setLoading] = useState<boolean>(false);
  const [progress, setProgress] = useState(0);
  const [fileData, setFileData] = useState(null);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  let context = useOutletContext<any>();
  const { dispatch, globalState } = useContext(globalContext);
  let navigate = useNavigate();
  let [, setSaveTrigger] = useState(null);
  let [data, setData] = useState(null);
  const [location, setLocation] = useState('');
  const [company, setCompany] = useState('');
  const [companySize, setCompanySize] = useState('');
  const [isCurrent, setIsCurrent] = useState('Current');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [startDateUnformatted, setStartDateUnformatted] = useState(null);
  const [endDateUnformatted, setEndDateUnformatted] = useState(null);
  const [jobtitle, setJobTitle] = useState('');
  const [jobLevel, setJobLevel] = useState('');
  const [jobFunction, setJobFunction] = useState('');
  const [industry, setIndustry] = useState('');
  const [industrySector, setIndustrySector] = useState('');
  const [boardAffiliation, setBoardAffiliation] = useState(['']);
  const [errors, setErrors] = useState<any>({});
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [downBtnDisable, setDownBtnDisable] = useState(false);
  const [linkedInUrl, setLinkedInUrl] = useState('');
  const [activelyLooking, setActivelyLooking] = useState<boolean>(false);
  const [historyWithHeidrick, setHistoryWithHeidrick] = useState<any>('');
  const [historyAdditionalInfo, setHistoryAdditionalInfo] = useState<any>('');
  const isLightMode = theme.palette.mode === 'light' ? 'light' : 'dark';
  const isMobileOrTablet = isMobile || isTablet;
  useEffect(() => {
    if (globalState?.account) {
      setData(globalState?.account);
      if (globalState?.account?.step_completed <= 4) {
        switch (globalState?.account?.step_completed) {
          case 1:
            navigate('/create-profile/experience');
            break;
          case 2:
            navigate('/create-profile/compensation-details');
            break;
          case 3:
            navigate('/create-profile/personal-details');
            break;
          case 4:
            navigate('/home');
            break;
        }
      }
    }
  }, [globalState?.account]);

  useEffect(() => {
    if (data?.experience) {
      setFormDataFromContext(data);
    }
  }, [data]);
    useEffect(() => {
    if (context?.data && context?.data?.resume) {
      const fileSize = `${context?.data?.resume?.document_extension} ${getSize(context?.data?.resume?.document_size)}`;
      const file = {
        ...context?.data?.resume,
        fileName: context?.data?.resume?.document_name,
        fileSize: fileSize,
      };
      setFileData(file);
      setLoading(false);
    }
  }, [context?.data]);

  const removeResume = async () => {
    setLoading(true);
    reactGAEvents('Create Profile - Resume', 'Delete Resume');
    await onDeleteYes();
    setLoading(false);
  };

  const onDeleteYes = async () => {
    reactGAEvents('profile-register-experience', 'delete-yes');
    if (fileData) {
      await Api.deleteResume(fileData.id)
        .then((res) => {
          if (res.success) {
            eventBus.dispatch('createAlert', {
              message: 'Your resume has been deleted.',
              severity: 'success',
            });
            setShowDeleteModal(false);
            setFileData(null);
          } else {
            eventBus.dispatch('createAlert', {
              message: 'Something went wrong when trying to delete your resume. Please try again.',
              severity: 'error',
            });
            setShowDeleteModal(false);
          }
        })
        .catch((err) => {
          eventBus.dispatch('createAlert', {
            message: 'Something went wrong when trying to delete your resume. Please try again.',
            severity: 'error',
          });
          setShowDeleteModal(false);
        });
    }
  };
  const getSize = (fileSize: any) => {
    if (fileSize > 1024) return (fileSize / 1024).toFixed(2) + ' MB';
    return fileSize + ' KB';
  };
  const handleChange = async (event: any) => {
    // do something with event data
    // validate file size
    let file: any = event.target.files[0];
    const chkForTagsRegValue = '^[a-zA-Z0-9_\\-_\\s.]*$' 
    let chkForTagsRegEx = new RegExp(chkForTagsRegValue); //NOSONAR
    if (!file) {
      // file wans't uploaded
      return;
    }

    if (file.type !== 'application/pdf' && file.type !== 'text/plain' && file.type !== 'application/msword' && file.type !== 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' && file.type !== 'application/rtf') {
      eventBus.dispatch('createAlert', {
        message: 'the selected file for resume upload has incorrect format.',
        severity: 'error',
      });
      setErrors({ ...errors, document_name: 'Please upload correct format: PDF (preferred), DOCX, DOC, RTF, TXT' });
      return;
    }

    if (file.size > 2097152) {
      setErrors({ ...errors, document_name: 'File size is too big' });
      return;
    }

    if (!chkForTagsRegEx.test(file.name)) {
      eventBus.dispatch('createAlert', {
        message: 'File name should not contain HTML Tags',
        severity: 'error',
      });
      setErrors({ ...errors, document_name: 'File name should not contain HTML Tags / Special Characters' });
      return;
    }

    if (file.name?.length > 100) {
      eventBus.dispatch('createAlert', {
        message: 'File name should not contain more than 100 characters',
        severity: 'error',
      });
      setErrors({ ...errors, document_name: 'File name should not contain more than 100 characters' });
      return;
    }

    event.preventDefault();
    if (fileData) {
      await onDeleteYes();
    }
    const onUploadProgress = (progressEvent: any) => {
      const { loaded, total } = progressEvent;
      let percent = Math.floor((loaded * 100) / total);
      setProgress(percent);
    };

    const formData = new FormData();
    formData.append('resume_file', event.target.files[0]);
    await Api.saveResumeWithProgress(formData, onUploadProgress)
      .then((res) => {
        if (res.success) {
          const fileSize = `${res?.response?.data?.document_extension} ${getSize(res?.response?.data?.document_size)}`;
          setProgress(0);
          setFileData({
            ...res?.response?.data,
            fileName: res?.response?.data?.document_name,
            fileSize: fileSize,
          });
          setErrors([]);
          // navigate to the next form
          eventBus.dispatch('createAlert', {
            message: 'Your profile has been updated',
            severity: 'info',
          });
        } else {
          const { data }:any = res;
          setErrors({ ...errors, document_name: data?.document_name?.[0] });
          setFileData(null);
          setProgress(0);
        }
      })
      .catch((err) => {
        setFileData(null);
        setProgress(0);
      });
  };

  function onSuccess() {
    navigate('/create-profile/compensation-details');
    setSaveTrigger(null);
  }

  function setFormDataFromContext(data: { [key: string]: React.SetStateAction<string> | React.SetStateAction<any> }) {
    setLocation(data?.experience?.['current_location']);
    setCompany(data?.experience?.['company_name']);
    setCompanySize(data?.experience?.['company_size']);
    setIsCurrent(data?.experience?.['is_current'] ? 'Current' : 'Previous');
    setJobTitle(data?.experience?.['job_title']);
    setActivelyLooking(data?.experience?.['actively_looking']);
    setJobLevel(data?.experience?.['job_level']);
    setJobFunction(data?.experience?.['job_function']);
    setIndustry(data?.experience?.['industry']);
    setIndustrySector(data?.experience?.['industry_sector']);
    setBoardAffiliation(data?.experience?.['board_affiliations']?.length ? data?.experience?.['board_affiliations'] : ['']);
    setHistoryWithHeidrick(data?.experience?.['history_with_heidrick']);
    setHistoryAdditionalInfo(data?.experience?.['history_additional_info']);
    // convert month to MM/YYYY
    let startDateF = formatDateString(data?.experience?.['start_date']);
    let endDateF = formatDateString(data?.experience?.['end_date']);
    setStartDate(startDateF);
    setEndDate(endDateF);
    setStartDateUnformatted(new Date(data?.experience?.['start_date']));
    if (data?.experience?.['end_date']) {
      setEndDateUnformatted(new Date(data?.experience?.['end_date']));
    }

    setLinkedInUrl(data?.personal_details?.['linkedin_url']);
  }

  function formatDateString(str: string) {
    if (str) {
      return str.substring(5, 7) + '/' + str.substring(0, 4);
    }
    return null;
  }

  function saveObject(event: { preventDefault: () => void }) {
    event.preventDefault();
    const chkForTagsRegValue = '^([^<]|a z|A Z|1 9|(.\\.))*$'
    const chkForTagsRegEx = new RegExp(chkForTagsRegValue); //NOSONAR
    const chkLinkedInRegValue = '^[-a-zA-Z0-9?&//=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9?&//=]*)$'
    const chkForTagsLinkedINRegEx = new RegExp(chkLinkedInRegValue); //NOSONAR
    const industryCheck = industry && industry !== 'Prefer not to respond' ? industry : '';
    const industrySectorCheck = industrySector && industrySector !== 'Prefer not to respond' ? industry : '';

    const jobTitleMsg = validateFields.validate({ name: jobtitle }, validationOptions)?.error?.details[0]?.message;
    const jobFunctionMsg = validateFields.validate({ dropDown: jobFunction }, validationOptions)?.error?.details[0]?.message;
    const jobLevelMsg = validateFields.validate({ dropDown: jobLevel }, validationOptions)?.error?.details[0]?.message;
    const locationMsg = validateFields.validate({ textField: location }, validationOptions)?.error?.details[0]?.message;
    const companyMsg = validateFields.validate({ name: company }, validationOptions)?.error?.details[0]?.message;
    const companySizeMsg = validateFields.validate({ dropDown: companySize }, validationOptions)?.error?.details[0]?.message;
    const industryMsg = validateFields.validate({ dropDown: industryCheck }, validationOptions)?.error?.details[0]?.message;
    const industrySectorMsg = validateFields.validate({ dropDown: industrySectorCheck }, validationOptions)?.error?.details[0]?.message;
    const historyAdditionalInfoMsg = validateFields.validate({ textFieldWithLength: (historyWithHeidrick === 'Heidrick Consultant' || historyWithHeidrick === 'Other') ? historyAdditionalInfo : 'true' }, validationOptions)?.error?.details[0]?.message;
    const monthYearRe = /^\d{2}\/\d{4}$/;
    const startDateIsValid = monthYearRe.test(startDate);
    const endDateIsValid = monthYearRe.test(endDate);
    const currentYear = new Date().getFullYear();
    const startDateYear = startDate?.split('/')?.[1];
    const endDateYear = endDate ? endDate?.split('/')?.[1] : null;
    const startDateParts = startDate ? startDate.split('/') : null;
    const endDateParts = endDate ? endDate.split('/') : null;
    const startMonth = startDateParts ? parseInt(startDateParts?.[0]) : null;
    const endMonth = endDateParts ? parseInt(endDateParts?.[0]) : null;
    let dateMsg: any = { start_date: '', end_date: '' };
    let boardAffiliationMsg: any = {};
    let board_affiliations_errors: any = {};
    const errorMsgTxt = 'Date must be in the following format: MM/YYYY';

    if ((!isCurrent && (!startDateIsValid || !endDateIsValid)) || (isCurrent && (!startDateIsValid && !endDateIsValid))) {
      dateMsg.start_date = errorMsgTxt;
      dateMsg.end_date = errorMsgTxt;
    } else if (isCurrent === 'Previous' && !endDateIsValid) {
      dateMsg.end_date = errorMsgTxt;
    } else if (isCurrent && !startDateIsValid) {
      dateMsg.start_date = errorMsgTxt;
    } else if (endDateYear && currentYear < parseInt(endDateYear)) {
      dateMsg.end_date = 'End date can not be in the future';
    } else if (currentYear < parseInt(startDateYear)) {
      dateMsg.start_date = 'Start date can not be in the future';
    } else if (isCurrent === 'Previous' && (endDateYear < parseInt(startDateYear)) || (endDateYear === startDateYear && startMonth > endMonth)) {
      dateMsg.end_date = 'End date should be greater than start date';
    }

    if (boardAffiliation.length !== [...new Set(boardAffiliation)].length) {
      boardAffiliationMsg.non_field_errors = 'No duplicates are allowed';
    }

    if (boardAffiliation?.[0] !== '' && boardAffiliation?.length !== 1) {
      if (boardAffiliation.length) {
        boardAffiliation.forEach((field: any, idx: number) => {
          if (!field) {
            board_affiliations_errors[`${idx}`] = 'This field may not be blank.';
          } else if (!chkForTagsRegEx.test(field)) {
            board_affiliations_errors[`${idx}`] = 'Text should not contain any HTML Tags / Special characters';
          }
        });
        if (Object.keys(board_affiliations_errors).length) {
          boardAffiliationMsg = board_affiliations_errors;
        }
      }
    }

    if (boardAffiliation?.[0] !== '' && boardAffiliation?.length === 1) {
      if (!chkForTagsRegEx.test(boardAffiliation[0])) {
        boardAffiliationMsg = { 0: 'Text should not contain any HTML Tags / Special characters' };
      }
    }

    if (jobTitleMsg || dateMsg.start_date || dateMsg.end_date || jobFunctionMsg || jobLevelMsg || locationMsg || companyMsg || companySizeMsg || industryMsg || industrySectorMsg || Object.keys(boardAffiliationMsg).length || historyAdditionalInfoMsg) {
      setErrors({
        job_title: jobTitleMsg,
        start_date: dateMsg.start_date,
        end_date: dateMsg.end_date,
        job_function: jobFunctionMsg,
        job_level: jobLevelMsg,
        current_location: locationMsg,
        company_name: companyMsg,
        company_size: companySizeMsg,
        industry: industryMsg,
        industry_sector: industrySectorMsg,
        board_affiliations: boardAffiliationMsg,
        history_additional_info: historyAdditionalInfoMsg
      });
      return;
    }

    if (linkedInUrl != '' && !chkForTagsLinkedINRegEx.test(linkedInUrl)) {
      setErrors({ linkedin_url: 'Linked in URL should not contain HTML Tags/Special Characters' });
      return;
    }

    setErrors({});
    setLoading(true);
    let boardPayload = boardAffiliation.length === 1 && boardAffiliation[0] === '' ? [] : boardAffiliation;

    let payload: any = {
      current_location: location,
      company_name: company,
      company_size: companySize,
      is_current: Boolean(isCurrent == 'Current'),
      job_title: jobtitle,
      job_level: jobLevel,
      job_function: jobFunction,
      industry: industry,
      industry_sector: industrySector,
      board_affiliations: boardPayload,
      start_date: startDate,
      end_date: isCurrent == 'Current' ? null : endDate,
      linkedin_url: linkedInUrl,
      actively_looking: activelyLooking,
      history_with_heidrick: historyWithHeidrick,
    };
    if (historyAdditionalInfo) {
      payload['history_additional_info'] = historyAdditionalInfo;
    }
    if (data?.experience?.['id']) {
      Api.updateExperience(payload, data?.experience?.['id'] as never)
        .then((res) => {
          apiSuccess(res);
        })
        .catch(() => {
          setLoading(false);
        });
    } else {
      Api.saveExperience(payload)
        .then((res) => {
          apiSuccess(res);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  }
  
  const apiSuccess = async (res: any) => {
    if (res.success) {
      // navigate to the next form
      await Api.getAccount()
        .then((res) => {
          let data = res.response.data;
          console.log('aacounts called from header on success');
          dispatch({ type: 'SET_ACCOUNT', payload: data });
          setLoading(false);
          onSuccess();
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    } else {
      const { data } = res;
      setLoading(false);
      setErrors(data);
    }
  };

  const monthFomat = (month: any) => {
    let newMonth = month;
    if (month?.toString()?.length == 1) {
      newMonth = month?.toString()?.padStart(2, '0');
    }
    return newMonth;
  };

  const radioOptions = [{ value: 'Current' }, { value: 'Previous' }];

  const handleRadioButtons = (target: any) => {
    setIsCurrent(target.value);
    if (target.value === 'Current') {
      setEndDate(null);
      setEndDateUnformatted(null);
    }
  };

  const handleDownload = (id: any) => {
    reactGAEvents('Profile Edit - Upload your resume', 'Download');
    setDownBtnDisable(true);
    Api.downloadResume(id)
      .then((res) => {
        if (res.success) setDownBtnDisable(false);
      })
      .catch((err) => {
        setDownBtnDisable(false);
      });
  };

  const onCloseDelete = () => setShowDeleteModal(false);

  const onOpenDelete = () => setShowDeleteModal(true);

  const ArrowForwardSharpIconStyled = styled(ArrowForwardSharpIcon)(() => ({
    color: themeStyle == 'light' ? '#66FCFC' : '#18768C',
  }));

  const backgroundLight = ResponsiveMobileTabletValue(isMobileOrTablet, 'url(../res/profiledetailMobileLight.svg)' , 'url(../res/profiledetailLight.svg)');
  const backgroundDark = ResponsiveMobileTabletValue(isMobileOrTablet , 'url(../res/profiledetailDarkMobile.svg)' , 'url(../res/profiledetailDark.svg)');

  const getWidth = (isMobileOrTablet: any, isLightMode: any) => {
    if (isMobileOrTablet) return '100%';
    return isLightMode ? '50%' : '80%';
  };

  const StyledHistoryInfo = {marginTop:'36px' };

  const getErrorMessage = (fieldName:any) => {
    return errors?.[fieldName] ?? null;
  }

  return (
    <>
      <CssBaseline />
      {loading && <Loader />}
      <Header />
      <Box sx={ResponsiveThemeValue (theme, { ...backgroundTopRight, display: 'grid' } , { ...backgroundTopRightDark, display: 'grid' })}>
        <Container maxWidth="lg" sx={ContainerStyle(isMobile, isTablet)}>
          <Box
            sx={{
              background: ResponsiveThemeValue(theme,backgroundLight , backgroundDark),
              ...backImage(isMobile, isTablet),
              width: getWidth(isMobileOrTablet, isLightMode),
              backgroundSize: ResponsiveMobileTabletValue(isMobileOrTablet, '90%' , '80%'),
            }}
          ></Box>
          <Box sx={contentWrapStyle(isMobile, isTablet)}>
            {Stepper(1)}
            <Box sx={styledCardWrappers(isMobile, isTablet)}>
              <Typography sx={titleStyle(themeStyle)}>Now provide some information about your professional background.</Typography>
              <Box sx={borderFormWrap(themeStyle)}>
                <Typography sx={subTitleStyle(themeStyle)}>Your current or most recent position</Typography>
                <Divider sx={dividerStyle(themeStyle)} />
                <Box sx={fieldStyle}>
                  <HDTextField
                    type="text"
                    lblText="Job title"
                    placeholder="Job Title"
                    value={jobtitle}
                    onChange={({ target }: any) => setJobTitle(target.value)}
                    error={errors?.job_title}
                    errorMsg={getErrorMessage('job_title')}
                    onClick={() => reactGAEvents('profile-register-experience', 'job-title')}
                    id='job-title'
                  />
                </Box>

                <Box sx={fieldStyle}>
                  {radioOptions?.map((item: any, index) => {
                    return (
                      <HDRadio
                        label={item?.value}
                        checked={item?.value === isCurrent}
                        name={''}
                        key={`${item.value}`}
                        value={undefined}
                        onChange={({ target }: any) => {
                          reactGAEvents('profile-register-experience', target?.value);
                          handleRadioButtons(target);
                        }}
                        hasError={errors?.is_current}
                        disabled={false}
                        showBackground={false}
                        id='profile-register-experience'
                      />
                    );
                  })}
                </Box>
                <Box sx={fieldStyle}>
                  <HDDatePicker
                    disabled={false}
                    disableRipple={true}
                    placeholder={'Text'}
                    id="start-date"
                    errorMsg={errors?.start_date}
                    lblText={'Start Date (MM / YYYY)'}
                    views={['year', 'month']}
                    inputFormat={'MM/yyyy'}
                    value={startDateUnformatted}
                    onChange={(e: any) => {
                      if (e?.c?.year === undefined || e?.c?.month === undefined) {
                        setStartDate('');
                      } else if (e) {
                        setStartDate(`${monthFomat(e?.c?.month)}/${e?.c?.year}`);
                        setStartDateUnformatted(new Date(e));
                      } else {
                        setStartDate('');
                      }
                    }}
                    onClick={() => reactGAEvents('profile-register-experience', 'start-date')}
                    error={errors?.start_date}
                  />
                </Box>
                {isCurrent === 'Previous' && (
                  <Box sx={{ marginTop: '10px' }}>
                    <Box sx={fieldStyle}>
                      <HDDatePicker
                        disabled={false}
                        disableRipple={true}
                        placeholder={'End Date'}
                        id={'end-date'}
                        lblText={'End Date (MM / YYYY)'}
                        views={['year', 'month']}
                        inputFormat={'MM/yyyy'}
                        minDate={startDateUnformatted}
                        value={endDateUnformatted}
                        onChange={(e: any) => {
                          if (e?.c?.year === undefined || e?.c?.month === undefined) {
                            setEndDate('');
                          } else if (e) {
                            setEndDate(`${monthFomat(e?.c?.month)}/${e?.c?.year}`);
                            setEndDateUnformatted(new Date(e));
                          } else {
                            setEndDate('');
                          }
                        }}
                        onClick={() => reactGAEvents('profile-register-experience', 'end-date')}
                        error={errors?.end_date}
                        errorMsg={errors?.end_date}
                      />
                    </Box>
                  </Box>
                )}

                <Box sx={fieldStyle}>
                  <HDCheckBox
                    id='actively-looking'
                    labelTxt={' I am actively looking for a new position'}
                    checked={activelyLooking}
                    value={activelyLooking}
                    onChange={({ target }: any) => {
                      setActivelyLooking(target.checked);
                    }}
                  />
                </Box>

                <Box sx={fieldStyle}>
                  <HDDropDown
                    dataTestId={'Function'}
                    fieldLabel={'Function'}
                    dropDownItem={formData.jobFunctionMenuNew}
                    fullWidth={true}
                    disabled={false}
                    defaultValue=""
                    value={jobFunction}
                    onChange={({ target }) => {
                      reactGAEvents('profile-register-experience', `function-${target.value}`);
                      setJobFunction(target.value);
                    }}
                    error={errors?.job_function}
                    errorMsg={getErrorMessage('job_function')}
                    id='function'
                  />
                </Box>

                <Box sx={fieldStyle}>
                  <HDDropDown
                    fieldLabel={'Job level'}
                    value={jobLevel}
                    onChange={({ target }) => {
                      reactGAEvents('profile-register-experience', `job-level-${target.value}`);
                      setJobLevel(target.value);
                    }}
                    error={errors?.job_level}
                    errorMsg={getErrorMessage('job_level')}
                    dropDownItem={formData.jobLevelMenuNew}
                    fullWidth={true}
                    disabled={false}
                    defaultValue=""
                    id='job-level'
                  />
                </Box>
                <Box sx={fieldStyle}>
                  <HDCitySelection
                    placeholder="City where you work"
                    label={'City where you work'}
                    type={'text'}
                    required={true}
                    value={location}
                    onChange={(value: any) => setLocation(String(value))}
                    onClick={() => reactGAEvents('profile-register-experience', 'work-city')}
                    error={errors?.current_location}
                    errorMsg={errors?.current_location ? errors.current_location : null}
                    disabled={false}
                    size={''}
                    id='city-where-you-work'
                  />
                </Box>

                <Box sx={fieldStyle}>
                  <HDTextField
                    type="text"
                    lblText="Linkedin URL(Optional)"
                    placeholder="Linkedin URL"
                    value={linkedInUrl}
                    onChange={({ target }: any) => setLinkedInUrl(target.value)}
                    onClick={() => reactGAEvents('profile-register-experience', 'linkedin-url')}
                    error={errors?.linkedin_url}
                    errorMsg={errors?.linkedin_url ? 'Enter a valid LinkedIn URL. URL needs to be linkedin.com/in/….' : null}
                    id='linked-in-url'
                  />
                </Box>
                <HDDocUpload
                  dataTestId={'fileUpload'}
                  showDeleteModal={showDeleteModal}
                  onOpenDelete={() => onOpenDelete()}
                  onCloseDelete={() => onCloseDelete()}
                  handleFileUpload={(e: any) => {
                    handleChange(e);
                    reactGAEvents('profile-register-experience', 'add-new-resume');
                  }}
                  handleFileDelete={(fileData: any) => {
                    removeResume();
                    reactGAEvents('profile-register-experience', 'Delete');
                  }}
                  downBtnDisable={downBtnDisable}
                  progress={progress}
                  fileData={fileData}
                  errorMsg={errors?.document_name}
                  handleClickDownload={(id: any) => {
                    handleDownload(id);
                    reactGAEvents('profile-register-experience', 'Download');
                  }}
                />
              </Box>

              <Box sx={borderFormWrap(themeStyle)}>
                <Typography sx={subTitleStyle(themeStyle)}>Your current or most recent company</Typography>
                <Divider sx={dividerStyle(themeStyle)} />
                <Box sx={fieldStyle}>
                  <HDTextField
                    type="text"
                    lblText="Company name"
                    placeholder="Company name"
                    value={company}
                    onChange={({ target }: any) => setCompany(target.value)}
                    onClick={() => reactGAEvents('profile-register-experience', 'company-name')}
                    error={errors?.company_name}
                    errorMsg={errors?.company_name ? errors.company_name : null}
                    id='company-name'
                  />
                </Box>
                <Box sx={fieldStyle}>
                  <HDDropDown
                    fieldLabel={'Company size'}
                    dropDownItem={formData.companySize}
                    fullWidth={true}
                    disabled={false}
                    defaultValue=""
                    value={companySize}
                    onChange={({ target }: any) => {
                      setCompanySize(target.value);
                      reactGAEvents('profile-register-experience', 'company-size');
                    }}
                    error={errors?.company_size}
                    errorMsg={errors?.company_size ? errors.company_size : null}
                    id='company-size'
                  />
                </Box>
                <Box sx={fieldStyle}>
                  <HDDropDown
                    fieldLabel={'Industry'}
                    dropDownItem={formData.industryMenuNew}
                    fullWidth={true}
                    disabled={false}
                    defaultValue=""
                    value={industry}
                    onChange={({ target }: any) => {
                      setIndustry(target.value);
                      setIndustrySector('');
                      reactGAEvents('profile-register-experience', `industry-${target.value}`);
                    }}
                    error={errors?.industry}
                    errorMsg={errors?.industry ? errors.industry : null}
                    id='industry'
                  />
                </Box>
                <Box sx={fieldStyle}>
                  <HDDropDown
                    fieldLabel={'Industry sector'}
                    dropDownItem={formData.industrySectorNew(industry)}
                    fullWidth={true}
                    disabled={false}
                    defaultValue=""
                    value={industrySector}
                    onChange={({ target }) => {
                      reactGAEvents('profile-register-experience', `sector-${target.value}`);
                      setIndustrySector(target.value);
                    }}
                    error={errors?.industry_sector}
                    errorMsg={errors?.industry_sector ? errors.industry_sector : null}
                    id='industry-sector'
                  />
                </Box>

                <Box sx={borderFormWrap(themeStyle)}>
                  <Typography sx={subTitleStyle(themeStyle)}>Your board affiliations (optional)</Typography>
                  <Divider sx={dividerStyle(themeStyle)} />

                  <HDMultipleTextField
                    setterFn={(e) => setBoardAffiliation(e)}
                    value={boardAffiliation}
                    buttonProps={{
                      text: 'Add more board affiliations',
                      startIcon: <AddCircleOutlineRoundedIcon />,
                    }}
                    error={errors?.board_affiliations}
                    errorMsg={errors?.board_affiliations?.non_field_errors ? errors.board_affiliations?.non_field_errors : errors.board_affiliations}
                  />
                </Box>

                <Box sx={borderFormWrap(themeStyle)}>
                  <Typography sx={subTitleStyle(themeStyle)}>Your history with Heidrick</Typography>
                  <Divider sx={dividerStyle(themeStyle)} />
                  <Box sx={fieldStyle}>
                    <Box>
                      <HDDropDown
                        fieldLabel={'How did you find Heidrick for Leaders?'}
                        dropDownItem={formData.HistoryWithHeidrickList}
                        fullWidth={true}
                        disabled={false}
                        defaultValue=""
                        value={historyWithHeidrick}
                        onChange={({ target }) => {
                          setHistoryWithHeidrick(target.value);
                          reactGAEvents('profile-register-experience', `history-${target.value}`); 
                          setHistoryAdditionalInfo('');                 
                        }}
                        id='historyWithHeidrick'
                      />
                    </Box>
                    {historyWithHeidrick === 'Heidrick Consultant' || historyWithHeidrick === 'Other' ? (
                      <Box sx={StyledHistoryInfo}>
                        <HDTextField
                          type='text'
                          lblText={historyWithHeidrick === 'Heidrick Consultant' ? 'Who referred you?' : 'Please specify'}
                          value={historyAdditionalInfo}
                          onChange={({ target }: any) => setHistoryAdditionalInfo(target.value)}
                          onClick={() => reactGAEvents('profile-register-experience', 'history_additional_info')}
                          error={errors?.history_additional_info}
                          errorMsg={errors?.history_additional_info ? errors.history_additional_info : null}
                          id='history_additional_info'
                        />
                      </Box>
                    ) : (
                      <></>
                    )}
                  </Box>
                </Box>
              </Box>
              <HDButton
                variant={'contained'}
                endIcon={<ArrowForwardSharpIconStyled />}
                text={'Next'}
                onClick={(e) => {
                  reactGAEvents('profile-register-experience', 'next');
                  saveObject(e);
                }}
                sx={btnStyle(themeStyle)}
                showAnimation={true}
              />
            </Box>
          </Box>
        </Container>
      </Box>
      <Footer />
    </>
  );
};

export default ProfessionalBackground;
